var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "programme"
  }, [_c('div', {
    staticClass: "header-mol"
  }, [_vm._m(0), _c('div', [_c('el-button', {
    attrs: {
      "type": "primary",
      "icon": "el-icon-plus"
    },
    on: {
      "click": _vm.showAddDialog
    }
  }, [_vm._v(" 添加佣金方案 ")])], 1)]), _c('el-table', {
    staticClass: "body",
    attrs: {
      "border": "",
      "data": _vm.list
    }
  }, [_c('el-table-column', {
    attrs: {
      "label": "佣金方案"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return _c('div', {
          staticClass: "y3"
        }, [_c('p', [_vm._v(_vm._s(scope.row.ruleName) + " " + _vm._s(scope.row.commissionAmount))]), _c('p', {
          staticClass: "color9"
        }, [_vm._v(" 适用于 " + _vm._s(scope.row.applyScope == 1 ? "全渠道" : scope.row.channelSplit) + " ")])]);
      }
    }])
  }), _c('el-table-column', {
    attrs: {
      "label": "更新人",
      "prop": "lastEditUser",
      "width": "150px"
    }
  }), _c('el-table-column', {
    attrs: {
      "label": "更新时间",
      "prop": "lastEditDate",
      "width": "250px"
    }
  }), _c('el-table-column', {
    attrs: {
      "label": "操作",
      "width": "120px"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c('el-button', {
          attrs: {
            "type": "text"
          },
          on: {
            "click": function click($event) {
              return _vm.showEditDialog(scope.row);
            }
          }
        }, [_vm._v("编辑")]), _c('el-button', {
          attrs: {
            "type": "text"
          },
          on: {
            "click": function click($event) {
              return _vm.deleRule(scope.row.id);
            }
          }
        }, [_vm._v("删除")])];
      }
    }])
  })], 1), _c('el-dialog', {
    staticClass: "f-dialog",
    attrs: {
      "visible": _vm.toggleDialog,
      "title": _vm.formData.id ? '编辑佣金方案' : '添加佣金方案',
      "width": "580px"
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.toggleDialog = $event;
      }
    }
  }, [_c('el-form', {
    ref: "form",
    attrs: {
      "model": _vm.formData,
      "label-width": "100px"
    }
  }, [_c('el-form-item', {
    attrs: {
      "label": "方案名称：",
      "rules": [_vm.rules.required()],
      "prop": "ruleName"
    }
  }, [_c('el-input', {
    staticStyle: {
      "width": "260px"
    },
    attrs: {
      "placeholder": "请输入",
      "maxlength": "30"
    },
    model: {
      value: _vm.formData.ruleName,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "ruleName", $$v);
      },
      expression: "formData.ruleName"
    }
  })], 1), _c('el-form-item', {
    staticClass: "commission-wrapper",
    attrs: {
      "label": "佣金奖励：",
      "prop": "commissionMethod",
      "rules": [_vm.rules.required(), _vm.rules.number]
    }
  }, [_c('el-form-item', {
    attrs: {
      "prop": "commissionMethod"
    }
  }, [_c('el-select', {
    staticStyle: {
      "width": "135px",
      "height": "38px",
      "line-height": "38px",
      "background": "#fff"
    },
    attrs: {
      "placeholder": "请选择"
    },
    model: {
      value: _vm.formData.commissionMethod,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "commissionMethod", $$v);
      },
      expression: "formData.commissionMethod"
    }
  }, _vm._l(_vm.options, function (item) {
    return _c('el-option', {
      key: item.value,
      attrs: {
        "label": item.label,
        "value": item.value
      }
    });
  }), 1)], 1), _c('div', {
    staticClass: "commission-box"
  }, [_c('el-form-item', {
    attrs: {
      "prop": "commissionAmountFrom",
      "rules": [_vm.rules.required(), _vm.rules.number]
    }
  }, [_c('el-input', {
    attrs: {
      "placeholder": _vm.formData.commissionMethod == 1 ? '最低点数' : '最低金额'
    },
    model: {
      value: _vm.formData.commissionAmountFrom,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "commissionAmountFrom", $$v);
      },
      expression: "formData.commissionAmountFrom"
    }
  })], 1), _vm._v(" - "), _c('el-form-item', {
    attrs: {
      "prop": "commissionAmountTo",
      "rules": [_vm.rules.required(), _vm.rules.number]
    }
  }, [_c('el-input', {
    attrs: {
      "placeholder": _vm.formData.commissionMethod == 1 ? '最高点数' : '最高金额'
    },
    model: {
      value: _vm.formData.commissionAmountTo,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "commissionAmountTo", $$v);
      },
      expression: "formData.commissionAmountTo"
    }
  })], 1)], 1), _c('span', {
    staticClass: "unit"
  }, [_vm._v(_vm._s(_vm.formData.commissionMethod == 1 ? "%" : "元"))])], 1), _c('el-form-item', {
    attrs: {
      "label": "适用范围：",
      "prop": "applyScope",
      "rules": [_vm.rules.required()]
    }
  }, [_c('el-radio-group', {
    staticClass: "x",
    model: {
      value: _vm.formData.applyScope,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "applyScope", $$v);
      },
      expression: "formData.applyScope"
    }
  }, _vm._l(_vm.rangeList, function (item, index) {
    return _c('el-radio-button', {
      key: index,
      attrs: {
        "label": item.value
      }
    }, [_vm._v(" " + _vm._s(item.name))]);
  }), 1)], 1), _vm.formData.applyScope == -1 ? _c('el-form-item', {
    staticClass: "choose-channel",
    attrs: {
      "prop": "channelIdArr",
      "rules": [_vm.rules.required('至少选择一个')]
    }
  }, [_c('el-checkbox-group', {
    model: {
      value: _vm.formData.channelIdArr,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "channelIdArr", $$v);
      },
      expression: "formData.channelIdArr"
    }
  }, _vm._l(_vm.channelList, function (item, index) {
    return _c('el-checkbox', {
      key: index,
      staticStyle: {
        "width": "150px"
      },
      attrs: {
        "label": item.id
      }
    }, [_vm._v(_vm._s(item.letTitle))]);
  }), 1)], 1) : _vm._e()], 1), _c('div', {
    attrs: {
      "slot": "footer"
    },
    slot: "footer"
  }, [_c('el-button', {
    on: {
      "click": function click($event) {
        _vm.toggleDialog = false;
      }
    }
  }, [_vm._v("取 消")]), _c('el-button', {
    attrs: {
      "type": "primary"
    },
    on: {
      "click": _vm.submit
    }
  }, [_vm._v("确 定")])], 1)], 1)], 1);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "info"
  }, [_c('h3', {
    staticClass: "title"
  }, [_vm._v("佣金方案")]), _c('p', {
    staticClass: "summary"
  }, [_vm._v("支持面向不同渠道，设置不同的佣金方案")])]);

}]

export { render, staticRenderFns }
<template>
  <div class="programme">
    <div class="header-mol">
      <div class="info">
        <h3 class="title">佣金方案</h3>
        <p class="summary">支持面向不同渠道，设置不同的佣金方案</p>
      </div>
      <div>
        <el-button type="primary" icon="el-icon-plus" @click="showAddDialog">
          添加佣金方案
        </el-button>
      </div>
    </div>
    <el-table border class="body" :data="list">
      <el-table-column label="佣金方案">
        <div slot-scope="scope" class="y3">
          <p>{{ scope.row.ruleName }} {{ scope.row.commissionAmount }}</p>
          <p class="color9">
            适用于
            {{ scope.row.applyScope == 1 ? "全渠道" : scope.row.channelSplit }}
          </p>
        </div>
      </el-table-column>
      <el-table-column
        label="更新人"
        prop="lastEditUser"
        width="150px"
      ></el-table-column>
      <el-table-column
        label="更新时间"
        prop="lastEditDate"
        width="250px"
      ></el-table-column>
      <el-table-column label="操作" width="120px">
        <template slot-scope="scope">
          <el-button type="text" @click="showEditDialog(scope.row)"
            >编辑</el-button
          >
          <el-button type="text" @click="deleRule(scope.row.id)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-dialog
      :visible.sync="toggleDialog"
      class="f-dialog"
      :title="formData.id ? '编辑佣金方案' : '添加佣金方案'"
      width="580px"
    >
      <el-form :model="formData" label-width="100px" ref="form">
        <el-form-item
          label="方案名称："
          :rules="[rules.required()]"
          prop="ruleName"
        >
          <el-input
            placeholder="请输入"
            maxlength="30"
            v-model="formData.ruleName"
            style="width:260px;"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="佣金奖励："
          prop="commissionMethod"
          :rules="[rules.required(), rules.number]"
          class="commission-wrapper"
        >
          <el-form-item prop="commissionMethod">
            <el-select
              v-model="formData.commissionMethod"
              placeholder="请选择"
              style="width:135px;height:38px;line-height:38px;background:#fff;"
            >
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <div class="commission-box">
            <el-form-item
              prop="commissionAmountFrom"
              :rules="[rules.required(), rules.number]"
            >
              <el-input
                :placeholder="
                  formData.commissionMethod == 1 ? '最低点数' : '最低金额'
                "
                v-model="formData.commissionAmountFrom"
              >
              </el-input>
            </el-form-item>
            -
            <el-form-item
              prop="commissionAmountTo"
              :rules="[rules.required(), rules.number]"
            >
              <el-input
                :placeholder="
                  formData.commissionMethod == 1 ? '最高点数' : '最高金额'
                "
                v-model="formData.commissionAmountTo"
              >
              </el-input>
            </el-form-item>
          </div>
          <span class="unit">{{
            formData.commissionMethod == 1 ? "%" : "元"
          }}</span>
        </el-form-item>
        <el-form-item
          label="适用范围："
          prop="applyScope"
          :rules="[rules.required()]"
        >
          <el-radio-group v-model="formData.applyScope" class="x">
            <el-radio-button
              :label="item.value"
              :key="index"
              v-for="(item, index) in rangeList"
            >
              {{ item.name }}</el-radio-button
            >
          </el-radio-group>
        </el-form-item>
        <el-form-item
          prop="channelIdArr"
          :rules="[rules.required('至少选择一个')]"
          v-if="formData.applyScope == -1"
          class="choose-channel"
        >
          <el-checkbox-group v-model="formData.channelIdArr">
            <el-checkbox
              :label="item.id"
              v-for="(item, index) in channelList"
              :key="index"
              style="width:150px;"
              >{{ item.letTitle }}</el-checkbox
            >
          </el-checkbox-group>
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button @click="toggleDialog = false">取 消</el-button>
        <el-button type="primary" @click="submit">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  getEstateCommissionList,
  updateCommission,
  createCommission,
  deleteCommission,
  getEnterpriseChannelStatus
} from "@/api/distribution";
import rules from "@/utils/rules";
const formData = {
  applyScope: 1,
  channelIdArr: [], // 指定渠道
  commissionAmount: "",
  commissionAmountFrom: "",
  commissionAmountTo: "",
  commissionMethod: 1,
  estateId: null,
  ruleName: ""
};
export default {
  data() {
    return {
      rules,
      options: [
        {
          value: 1,
          label: "按成交百分比"
        },
        {
          value: 2,
          label: "按一口价奖励"
        }
      ],
      rangeList: [
        {
          name: "全部渠道",
          value: 1
        },
        {
          name: "指定渠道",
          value: -1
        }
        /*  {
          name: "公司内部渠道",
          value: 2
        },
        {
          name: "自定义自有渠道",
          value: 3
        } */
      ],
      channelList: [],
      rangeObj: {
        1: "全部渠道",
        2: "公司内部渠道",
        3: "自定义自有渠道"
      },
      list: [], //榜单列表
      toggleDialog: false,
      formData: JSON.parse(JSON.stringify(formData))
    };
  },
  computed: {
    estateInfo() {
      return this.$store.state.estateInfo;
    }
  },
  created() {
    this.getEstateCommissionList();
    this.getEnterpriseChannelStatus();
  },
  methods: {
    async getEstateCommissionList() {
      let query = {
        estateId: this.estateInfo.estateId
      };
      const data = await getEstateCommissionList(query);
      if (data) {
        this.list = data;
      }
    },
    async getEnterpriseChannelStatus() {
      // 获取渠道信息
      let query = { estateId: this.estateInfo.estateId, channelSource: 2 };
      const data = await getEnterpriseChannelStatus(query);
      if (data) {
        this.channelList = data.channelVos;
      }
    },
    showAddDialog() {
      //点击创建
      this.formData = this.$deepClone(formData);
      this.formData.estateId = this.estateInfo.estateId;
      this.toggleDialog = true;
      this.$nextTick(() => {
        this.$refs.form.clearValidate();
      });
    },
    async showEditDialog(row) {
      //点击编辑
      this.formData = {
        ...this.$deepClone(row),
        channelIdArr: row.channelIdArr || []
      };
      this.formData.estateId = this.estateInfo.estateId;
      this.toggleDialog = true;
      this.$nextTick(() => {
        this.$refs.form.clearValidate();
      });
    },
    async deleRule(id) {
      //删除榜单规则
      this.$confirm("删除后无法恢复，确定删除该佣金方案吗？", "删除佣金方案", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(async () => {
          const res = await deleteCommission({ id });
          if (res) {
            this.$showSuccess("删除成功");
            this.getEstateCommissionList();
          }
        })
        .catch(() => {});
    },
    submit() {
      //提交表单
      this.$refs.form.validate(async valid => {
        if (!valid) {
          return;
        }
        let unit = this.formData.commissionMethod == 1 ? "点数" : "金额";
        let fromNum = Number(this.formData.commissionAmountFrom);
        let formTo = Number(this.formData.commissionAmountTo);
        if (this.formData.commissionAmountTo == 0) {
          this.$showError(`最高${unit}不能为0`);
          return;
        }
        if (fromNum > formTo) {
          this.$showError(`最低${unit}不能大于最高${unit}`);
          return;
        }
        if (unit == "点数" && formTo > 100) {
          this.$showError(`最高点数不能大于100`);
          return;
        }
        this.formData.applyScope == 1 ? this.formData.applyScope : null; // 指定范围，applyScope传null
        const fn = this.formData.id ? updateCommission : createCommission;
        const res = await fn(this.formData);
        if (res) {
          this.$showSuccess("提交成功");
          this.formData = this.$deepClone(formData);
          this.$refs.form.clearValidate();
          this.getEstateCommissionList();
          this.toggleDialog = false;
        }
      });
    }
  }
};
</script>
<style lang="scss">
.programme {
  .commission-rule-item {
    & > .el-form-item__content > .el-form-item__error {
      left: 136px;
    }
  }
  .el-input-group__prepend {
    color: #333;
  }
  .limit-form-item {
    .el-form-item {
      margin-top: 0;
      &:nth-child(n + 3) {
        margin-top: 20px;
      }
    }
  }
  .form-item-flex {
    .f-form-block {
      display: flex;
    }
    .el-form-item__content {
      display: flex;
      .el-input {
        & + .el-input {
          margin-left: 20px;
        }
      }
    }
  }
  .index-wrap {
    display: flex;
    & > div {
      display: flex;
      margin-right: 30px;
      & > span {
        margin-right: 15px;
      }
      .el-input {
        width: 110px;
      }
    }
  }
  .rank-count {
    display: flex;
    flex-wrap: wrap;
    & > div {
      width: 50%;
      display: flex;
      margin-top: 20px;
      .el-input {
        width: 60px;
        margin-left: 15px;
      }
    }
  }
  .body {
    .status-close {
      td {
        .cell {
          color: #999;
        }
      }
    }
  }
}
.choose-channel {
  .el-form-item__content {
    background: #eee;
    padding: 10px 20px;
  }
}
</style>

<style scoped lang="scss">
::v-deep .el-dialog__body {
  max-height: calc(100vh - 400px);
  overflow-y: auto;
}
.commission-wrapper {
  ::v-deep .el-form-item__content {
    display: flex;
    .el-select .el-input__inner {
      border-radius: 4px 0 0 4px;
    }
  }
  .commission-box {
    height: 40px;
    border: 1px solid #dcdfe6;
    border-left: none;
    border-right: none;
    display: flex;
    .el-input {
      width: 120px;
    }
    ::v-deep .el-input__inner {
      text-align: center;
      border: none;
      background: none;
    }
  }
  .unit {
    line-height: 40px;
    height: 40px;
    width: 40px;
    text-align: center;
    font-size: 14px;
    color: #606266;
    background: #f5f7fa;
    font-weight: 500;
    border-radius: 0 4px 4px 0;
    border: 1px solid #dcdfe6;
  }
}
.programme {
  padding: 30px;
  min-height: 100%;
  box-shadow: 0 0 5px #ddd;
  border-radius: 5px;
  .head {
    .title {
      h3 {
        font-size: 16px;
        line-break: 16px;
        padding-left: 20px;
        border-left: 3px solid $primary;
      }
      p {
        margin-top: 10px;
        padding-left: 20px;
        color: $color9;
      }
    }
  }
  .body {
    margin-top: 30px;
    .tips {
      margin-top: 10px;
      color: #999;
    }
  }
}
.rank-bgi-uploader {
  width: 120px;
  height: 100px;
  border: 1px dashed #ccc;
  line-height: 1;
  margin-right: 20px;
  cursor: pointer;
  overflow: hidden;
  .img {
    width: 120px;
    height: 100px;
  }
  .el-icon-plus {
    margin-bottom: 15px;
    font-size: 32px;
    font-weight: bold;
    color: $primary;
  }
  &:hover {
    border-color: $primary;
  }
}
.tips {
  color: #8691a3;
}
</style>
